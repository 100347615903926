import React from 'react'
import Layout from '../components/layout'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      winHeight: '100vh'
    }
  }
  setWindowHeight() {
    this.setState({
      winHeight: window.innerHeight
    })
  }
  render() {
    return <Layout />
  }
}

export default IndexPage
